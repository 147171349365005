
/**
 * CanvasSettings.js
 * 
 * This file accomplishes a few things in Fabric:
 *  1. 
 * 
 */

import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import Annotation from './util/Annotation'
import { COLOR_LIST, STROKE_LIST } from './constants.js'

let deletedObjs = 0

export function resetFabric() {
  deletedObjs = 0
}

export function setupCanvasKeyBinds(canvas, setBboxes) {
  document.addEventListener("keydown", (key)=>{
    if (key.repeat) { return }
    if (key.code === 'Backspace') {
      deletedObjs += 1
      let to_remove = canvas.getActiveObject()
      canvas.remove(to_remove)
      updateBboxes(canvas, setBboxes)
    }
  })
}

/* Correct bounding box to fit within canvas. */
function fitBoundingBoxIntoCanvas(width, height, offsetX, offsetY, rect) {
  if (width < 0 || height < 0) {
    if (width < 0) {
      rect.set('width', -width)
      rect.set('height', height > 0 ? height : -height)
      rect.set('left', offsetX)
    }
    if (height < 0) {
      rect.set('width', width > 0 ? width : -width)
      rect.set('height', -height)
      rect.set('top', offsetY)
    }
  } else {
    rect.set('width', width)
    rect.set('height', height)
  }
  rect.set('strokeUniform', true)
  rect.setCoords();
}

export function updateCanvasSettings(canvas, setBboxes) {
  setupCanvasKeyBinds(canvas, setBboxes)
  canvas.selection = false
  canvas.off('mouse:down');
  canvas.on('mouse:down', function (option) {
    handleMouseDown(option, canvas, setBboxes)
  });
}

/* Logic to handle mouse down events. */
function handleMouseDown(option, canvas, setBboxes, startX, startY) {
  if (option.target != null) {
    // Select an existing box.
    handleSelectBox(option, canvas, setBboxes)
  } else {
    // Create new box.
    handleNewBox(option, canvas, setBboxes, startX, startY)
  }
}

function handleSelectBox(option, canvas, setBboxes) {
  /* Handle selection of an existing box */
  canvas.on('mouse:up', function(option) {
    canvas.off('mouse:up')
    updateBboxes(canvas, setBboxes)
  })
  return
}

function handleNewBox(option, canvas, setBboxes) {
  let startY = option.e.offsetY;
  let startX = option.e.offsetX;
  /* Add rect to canvas on mouse down */
  let rect = generateRect(option, canvas)
  canvas.add(rect);
  canvas.setActiveObject(rect)
  /* Mouse down -> mouse move */
  canvas.on('mouse:move', function (option) {
    handleNewBoxMouseMove(option, canvas, rect, startX, startY)
  })
  canvas.on('mouse:up', function () {
    handleNewBoxMouseUp(option, canvas, setBboxes)
  });
}

function handleNewBoxMouseMove(option, canvas, rect, startX, startY) {
  let e = option.e;
  let width = e.offsetX - startX
  let height = e.offsetY - startY
  fitBoundingBoxIntoCanvas(width, height, e.offsetX, e.offsetY, rect)
  canvas.renderAll()
}

function handleNewBoxMouseUp(option, canvas, setBboxes) {
  canvas.off('mouse:move');
  canvas.off('mouse:up');
  updateBboxes(canvas, setBboxes)
}

function generateRect(option, canvas) {
  var startY = option.e.offsetY,
  startX = option.e.offsetX;
  let bbox_color = COLOR_LIST[
    (canvas.getObjects().length + deletedObjs) % COLOR_LIST.length]
  let stroke_color = STROKE_LIST[
    (canvas.getObjects().length + deletedObjs) % STROKE_LIST.length]
  let rect = new fabric.Rect({
    top : startY,
    left : startX,
    width : 0,
    height : 0,
    fill: bbox_color,
    stroke: stroke_color,
    strokeWidth: 2,
    noScalecache: false,
    strokeUniform: true,
    hasBorders: false,
    cornerColor: stroke_color,
    hasRotatingPoint: false,
    cornerSize: 10
  });
  rect.uuid = uuidv4()
  return rect
}

function unpackCoordsFromAnnotationStr(annotation) {
  let annotObj = Annotation.buildFromStr(annotation, null)
  let coords = {
    xmin : annotObj.getXmin(),
    xmax : annotObj.getXmax(),
    ymin : annotObj.getYmin(),
    ymax : annotObj.getYmax()
  }
  return coords
}

export function createBboxFromStr(annotationStr, canvas, setBboxes) {
  let coords = unpackCoordsFromAnnotationStr(annotationStr)
  coords = convertToPixels(coords, canvas)
  let uuid = createBboxFromCoord(coords, canvas)
  updateBboxes(canvas, setBboxes)
  return uuid
}

function createBboxFromCoord(coords, canvas) {
  let bbox_color = COLOR_LIST[
    (canvas.getObjects().length + deletedObjs) % COLOR_LIST.length]
  let stroke_color = STROKE_LIST[
    (canvas.getObjects().length + deletedObjs) % STROKE_LIST.length]
  let rect = new fabric.Rect({
    top: coords.ymin,
    left: coords.xmin,
    width: coords.xmax - coords.xmin,
    height: coords.ymax - coords.ymin,
    fill: bbox_color,
    stroke: stroke_color,
    strokeWidth: 2,
    noScalecache: false,
    strokeUniform: true,
    hasBorders: false,
    cornerColor: stroke_color,
    hasRotatingPoint: false,
    cornerSize: 10,
    scaleX: 1,
    scaleY: 1
    });
  rect.uuid = uuidv4()
  canvas.add(rect)
  canvas.setActiveObject(rect)
  return rect.uuid
}

function convertToPixels(coords, canvas) {
  return {
    xmin: coords.xmin * canvas.width,
    ymin: coords.ymin * canvas.height,
    xmax: coords.xmax * canvas.width,
    ymax: coords.ymax * canvas.height
  }
}

export function updateBboxes(canvas, setBboxes) {
  let bboxes = []
  
  canvas.getObjects().forEach((obj, index)=>{
    // Remove small boxes.
    if (obj.width <= 1 || obj.height <= 1) {
      canvas.remove(obj)
    }
    let canvas_w = canvas.width
    let canvas_h = canvas.height
    // Convert bounding box attributes to percentages.
    let obj_w = obj.width * obj.scaleX   // Obj does not store true width/height
    let obj_h = obj.height * obj.scaleY  // We must calculate it.
    // Create bounding box for external (to Fabric) use.
    let _box = {
      xmin: obj.left/canvas_w,
      ymin: obj.top/canvas_h,
      xmax: (obj.left + obj_w)/canvas_w,
      ymax: (obj.top + obj_h)/canvas_h,
      rect: obj,
      color: rgba2hex(obj.stroke)
    }
    bboxes.push(_box)
  })
  setBboxes(bboxes)
}

function rgba2hex(orig) {
  var a, isPercent,
    rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
    alpha = (rgb && rgb[4] || "").trim(),
    hex = rgb ?
    (rgb[1] | 1 << 8).toString(16).slice(1) +
    (rgb[2] | 1 << 8).toString(16).slice(1) +
    (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

  if (alpha !== "") {
    a = alpha;
  } else {
    a = 1;
  }
  // multiply before convert to HEX
  a = ((a * 255) | 1 << 8).toString(16).slice(1)
  hex = hex + a;
  return hex.slice(0,-3); // Remove opacity
}