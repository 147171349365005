/**
 * Canvas component
 * This component utilizes Fabric to create bounding boxes on an image.
 * @author Jeremy Aftem
 */

import React, { useState, useEffect, useRef } from 'react';
import {fabric} from 'fabric';
import Annotation from './util/Annotation'
import './Canvas.css';
import BoxPane from './boxpane/BoxPane'
import useWindowSize from '../../../common/window/WindowSize';
import {
  resetFabric,
  updateCanvasSettings,
  createBboxFromStr } from './CanvasSettings';

const Canvas = props => {
 /*---------------------------------------------------------------------------
  * State
  *--------------------------------------------------------------------------*/
  const [currImage, setCurrImage] = useState(null);
  const [currImageName, setCurrImageName] = useState(null);
  const [bboxes, setBboxes] = useState([]);
  /* Previous annotations are annotations that existed before user session. */
  const [prevAnnotations, setPrevAnnotations] = useState(
    [
      //Annotation.buildFromStr('test.png,userhold@gmail.com,0,1,0.19320660641944531,0.2979744468681832,0.4312870052976005,0.6668951906097433,false,false,false,false,false,0,Background', 'uuidtest')
    ]
  );
  const [canvasDim, setCanvasDim] = useState({width: 1, height: 1});
  const [enableAnnotations, setEnableAnnotations] = useState(false);
  const canvas = useRef(null);
  const size = useWindowSize();

 /*---------------------------------------------------------------------------
  * Hooks
  *--------------------------------------------------------------------------*/
 
  useEffect(()=>{
    if (canvas.current != null) { updateCanvas() }
  }, [size])

  useEffect(()=>{
    if (canvas.current != null) { updateCanvas() }
  }, [props.menuWidth])

  useEffect(()=>{
    if (canvas.current != null) {
      setEnableAnnotations(true)
    } else {
      setEnableAnnotations(false)
    }
  }, [currImage])
  
  useEffect(() => {
    // Reset Fabric
    resetFabric()
    initFabric()
    if (props.imageMeta == null) {
      // Teardown canvas with no image.
      teardownCanvas()
    } else {
      // Setup a new canvas with the new image.
      setupCanvas()
    }
  }, [props])

  useEffect(()=>{
    if (props.annotationStrs === null) { return }
    let annots = props.annotationStrs.map((str)=>{
      return Annotation.buildFromStr(str)
    })
    setPrevAnnotations(annots)
  }, [props.annotationStrs])
  
  useEffect(()=> {
    if (currImage != null) {
      updateCanvas()
    }
  }, [currImageName, currImage])

  useEffect(() => {
  }, [prevAnnotations])

  useEffect(()=> {
  }, [bboxes])

  useEffect(() => {
    refreshCanvas()
  }, [canvasDim])

 /*---------------------------------------------------------------------------
  * Helper func.
  *--------------------------------------------------------------------------*/

  function teardownCanvas() {
    disableCanvas()
    setBboxes([])
    setEnableAnnotations(false)
    return
  }

  function setupCanvas() {
    setCurrImage(props.imageMeta.data);
    setCurrImageName(props.imageMeta.path);
    setBboxes(props.bboxes)
  }

  function disableCanvas() {
    canvas.current.off('mouse:down')
    canvas.current.off('mouse:up')
  }

  function refreshCanvas() {
    scaleBoxesToCanvas()
    removeCanvasBboxes()
    if (currImage == null) { return; }
    buildBoxesFromAnnotations()
    updateCanvasSettings(canvas.current, setBboxes)
    updateCanvasBoxes()
  }

  function buildBoxesFromAnnotations() {
    prevAnnotations.forEach((annot)=>{
      let uuid = createBboxFromStr(annot.getStr(), canvas.current, setBboxes)
      annot.setUuid(uuid)
    })
    setPrevAnnotations([...prevAnnotations])
  }

  function scaleBoxesToCanvas() {
    if (bboxes != null) {
      bboxes.forEach((box)=>{
        box.rect.set('width',  (box.xmax - box.xmin) * canvasDim.width)
        box.rect.set('height', (box.ymax - box.ymin) * canvasDim.height)
        box.rect.set('left', box.xmin * canvasDim.width)
        box.rect.set('top', box.ymin * canvasDim.height)
      })
    }
  }

  function initFabric() {
    if (canvas.current != undefined) {
      canvas.current.clear()
    } else {
      canvas.current = new fabric.Canvas('canvas', { fireMiddleClick: true })
    }
  }
  
  function getMaxWidth() {
    return size.width - 60 - props.menuWidth - 300
  }

  function getMaxHeight() {
    return (size.height) - 120
  }

  function updateCanvasDimensions(img) {
    let height = img['height']
    let width = img['width']
    let max_width = getMaxWidth()
    let max_height = getMaxHeight()
    let dims = calculateCanvasDimensions(width, height, max_width, max_height)
    let width_height = {width: dims.width, height: dims.height}
    canvas.current.setDimensions(width_height)
    setCanvasDim(width_height)
    return dims.ratio
  }

  /**
   * Calculate new canvas dimensions if width or height of image exceeds
   * available space.
   */
  function calculateCanvasDimensions(width, height, max_width, max_height) {
    let dims = {}
    if (width >= max_width && height >= max_height) {
      dims = calcOverflowsWidthHeight(width, height, max_width, max_height)
    } else if (width > max_width && height < max_height) {
      dims = calcOverflowsWidth(width, height, max_width)
    } else if (height > max_height && width < max_width) {
      dims = calcOverflowsHeight(width, height, max_height)
    } else {
      dims = {width: width, height: height, ratio: 1}
    }
    return dims
  }

  /**
   * If image overflows both width and height of available space.
   */
  function calcOverflowsWidthHeight(width, height, max_width, max_height) {
    let ratio = 1
    let width_ratio = max_width/width
    let height_ratio = max_height/height
    if (height_ratio < width_ratio) {
      ratio  = height_ratio 
      width  = ratio * width
      height = max_height
    }
    if (width_ratio <= height_ratio) {
      ratio  = width_ratio
      height = ratio * height
      width  = max_width
    }
    return { width, height, ratio }
  }
  
  /**
   * If image overflows only width of available space.
   */
  function calcOverflowsWidth(width, height, max_width) {
    let ratio = 1
    ratio  = max_width/width
    height = ratio * height
    width  = max_width
    return { width, height, ratio }
  }

  /**
   * If image overflows only height of available space. 
   */
  function calcOverflowsHeight(width, height, max_height) {
    let ratio = 1
    ratio  = max_height/height
    width  = ratio * width
    height = max_height
    return { width, height, ratio }
  }

  function updateCanvasImage() {
    let ratio = 1
    let imageData = null
    if (props.imageMeta != null) {
      imageData = props.imageMeta.data
    }
    fabric.Image.fromURL(imageData, function(img) {
      ratio = updateCanvasDimensions(img)
      canvas.current.setBackgroundImage(
        img,
        canvas.current.renderAll.bind(canvas.current), {
          scaleX: ratio,
          scaleY: ratio
      });
    })
  }

  function updateCanvasBoxes() {
    if (bboxes == null) { return }
    removeCanvasBboxes()
    bboxes.forEach((bbox) =>{
      canvas.current.add(bbox.rect)
    })
  }

  function removeCanvasBboxes() {
    canvas.current.getObjects().forEach((el)=>{
      canvas.current.remove(el)
    })
  }

  function updateCanvas() {
    removeCanvasBboxes()
    updateCanvasImage()
    updateCanvasSettings(canvas.current, setBboxes)
    updateCanvasBoxes()
  }

 /*---------------------------------------------------------------------------
  * Return
  *--------------------------------------------------------------------------*/
  return (
    <div className="Canvas" id="CanvasWrapper">
      <div className="Canvas-image">
        <canvas id="canvas"/>
      </div>
      <div className="Canvas-annotations">
        <BoxPane
          enable={enableAnnotations}
          boxes={bboxes}
          prevAnnotations={prevAnnotations}
          imageMeta={props.imageMeta}
          teardown={props.teardown}
          labels={props.labels}
        />
      </div>
    </div>
  )
}
export default Canvas;