import AWS from 'aws-sdk';
import CognitoUser from './cognito';
const BUCKET = 'cvision-annotations215421-dev'

let instance = null;

export default class S3 {

  constructor(user) {
    if (user === undefined) throw 'S3 needs a user.';
    this._cognito_user = CognitoUser.getInstance(user)
    this._s3 = new AWS.S3()
  }

  static getInstance(user) {
    if (!instance) {
      instance = new S3(user)
    }
    return instance
  }

  async listObjs(prefix, delimiter='', limit=1000) {
    let params = {
      Bucket: BUCKET,
      Prefix: prefix,
      Delimiter: delimiter,
      MaxKeys: limit
    }
    try {
      let data = await this._s3.listObjects(params).promise()
      return data
    } catch (error) {
      console.log(error, error.stack)
      return null
    }
  }

  async listObjsV2(prefix, delimiter='', limit=1000) {
    let params = {
      Bucket: BUCKET,
      Prefix: prefix,
      Delimiter: delimiter,
      MaxKeys: limit,
    }
    try {
      let ret = await this._s3.listObjectsV2(params).promise()
      let contents = ret.Contents
      while (ret.IsTruncated == true) {
        params.ContinuationToken = ret.NextContinuationToken
        ret = await this._s3.listObjectsV2(params).promise()
        contents = contents.concat(ret.Contents)
      }
      return contents
    } catch (error) {
      console.log(error, error.stack)
      return null
    }
  }

  async copyObj(source, destination) {
    let params = {
      Bucket: BUCKET,
      CopySource: BUCKET + '/' + source,
      Key: destination
    }
    try {
      let data = await this._s3.copyObject(params).promise()
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async moveObj(source, target) { 
    let cpyParams = {
      Bucket: BUCKET,
      CopySource: BUCKET + '/' + source,
      Key: target
    }
    let delParams = {
      Bucket: BUCKET,
      Key: source
    }
    try {
      let data = await this._s3.copyObject(cpyParams).promise()
      let ret = await this._s3.deleteObject(delParams).promise()
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async deleteObj(target) {
    let params = {
      Bucket: BUCKET,
      Key: target
    }
    try {
      let data = await this._s3.deleteObject(params).promise()
      return data
    } catch(error) {
      console.log(error, error.stack)
      return false
    }
  }

  async getFile(target) {
    let params = {
      Bucket: BUCKET,
      Key: target,
    }
    try {
      let data = await this._s3.getObject(params).promise()
      return data
    } catch(error) {
      console.log(error, error.stack)
      return null
    }
  }

  async getCsv(target, level) {
    let key = target
    if (level == 'private') {
      key = this.privatePath(target)
    }
    let params = {
      Bucket: BUCKET,
      Key: key,
    }
    try {
      let data = await this._s3.getObject(params).promise()
      let text = await new Response(data.Body).text()
      return text
    } catch(error) {
      console.log(error, error.stack)
      return null
    }
  }

  async putFile(target, data, level) {
    let key = target
    if (level == 'private') {
      key = this.privatePath(target)
    }
    let params = {
      Bucket: BUCKET,
      Key: key,
      Body: data
    }
    try {
      let ret = await this._s3.putObject(params).promise()
      return ret
    } catch (error) {
      return null
    }
  }

  async uploadFile(target, data) {
    let params = {
      Bucket: BUCKET,
      Key: target,
      Body: data
    }
    try {
      let ret = await this._s3.upload(params).promise()
      return ret
    } catch (error) {
      return null
    }
  }

  privatePath(target) {
    return 'private/' + CognitoUser.getInstance().getIdentityId() + '/' + target
  }
}