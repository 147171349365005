import CognitoUser from '../cognito';
import S3 from '../s3';
import ampS3 from '../amplify/ampS3';

const PRIVATE_DIR = 'private/'
const PUBLIC_DIR  = 'public/'
const ANNOT_EXT   = '.csv'
const ROOT_DIR    = 'raw/'

export default class AnnotationMngr {

  static async copyImageToUserDir(imageName) {
    let _s3 = S3.getInstance()
    let source = PUBLIC_DIR + imageName
    let identityId = CognitoUser.getInstance().getIdentityId()
    let imagepath = imageName.split('/')
    imagepath.splice(imagepath.length-1, 0, 'train')
    imagepath = imagepath.join('/')
    let username = CognitoUser.getInstance().getUsername()
    let destination = PRIVATE_DIR + identityId + '/' + username + '/' + imagepath
    return await _s3.copyObj(source, destination)
  }

  static async copyLabelsToUserDir(path) {
    let identityId = CognitoUser.getInstance().getIdentityId()
    let username = CognitoUser.getInstance().getUsername()

    let public_labels_path = PUBLIC_DIR + path
    let private_labels_path = PRIVATE_DIR + identityId + '/' + username + '/' + path
    let _s3 = S3.getInstance()
    let data = await _s3.listObjs(private_labels_path, '')
    if (data == null) { return }
    if (data.Contents.length == 0) {
      return await _s3.copyObj(public_labels_path, private_labels_path)
    } else {
      return null
    }
  }

  /**
   * Save Annotations 
   * @param {Annotation} annotations - annotation objects
   */
  static async saveAnnotations(author, filename, annotations) {
    let target = PRIVATE_DIR + author.id + '/' + author.username + '/' 
      + filename + ANNOT_EXT
    let _s3 = S3.getInstance()
    let data = AnnotationMngr.generateAnnotationFile(annotations)
    return await _s3.putFile(target, data)
  }

  static generateAnnotationFile(annotations) {
    let annotStr = ''
    annotations.forEach((el)=>{
      annotStr += el.getStr() + '\n'
    })
    return annotStr
  }

  static async getCsv(csvpath) {
    let _s3 = S3.getInstance()
    let lines = await _s3.getCsv(csvpath, 'private')
    lines = lines.split('\n')
    lines = lines.filter((el) => {
      if (el === '') return false
      else return true
    })
    return lines
  }

  static async getCsvAsAdmin(csvpath) {
    let _s3 = S3.getInstance()
    let data = await _s3.getFile(csvpath)
    let text = await new Response(data.Body).text()
    let lines = text.split('\n')
    lines = lines.filter((el)=>{
      if (el === '') return false
      else return true
    })
    return lines
  }

  static async listUsers() {
    let _s3 = S3.getInstance()
    let keys = await _s3.listObjs(PRIVATE_DIR, ROOT_DIR)
    let users = keys.CommonPrefixes.map((el)=>{
      return {
        username: el.Prefix.split('/')[2],
        id: el.Prefix.split('/')[1]
      }
    })
    return users
  }

  static async listDirsOfUser(user) {
    let prefix = PRIVATE_DIR + user.id + '/' + user.username + '/raw/'
    let _s3 = S3.getInstance()
    let keys = await _s3.listObjs(prefix, '/')
    let _dirs = keys.CommonPrefixes.map((el)=>{
      return {
        path: el.Prefix,
        display: el.Prefix.split('/').splice(3,).join('/')
      }
    })
    return _dirs
  }

  static async listFiles(prefix, delimiter) {
    let _s3 = S3.getInstance()
    let keys = await _s3.listObjs(prefix, delimiter)
    let ret = keys.Contents.map((el)=>el.Key)
    return ret
  }

  static async readLabels(filepath) {
    let _ampS3 = new ampS3()
    let labels = await _ampS3.getText(filepath, 'public')
    labels = labels.split('\n').filter((el)=>el != '')
    return labels
  }

  static async reportImage(imageMeta) {
    let _s3 = S3.getInstance()
    let source_path = PUBLIC_DIR + imageMeta.path
    let target_path = PUBLIC_DIR + 'reported/' + imageMeta.path.split('/').splice(1,).join('/')
    return await _s3.moveObj(source_path, target_path)
  }

  static async deleteAnnotation(imageMeta) {
    if (!imageMeta.isEdit) {
      return
    }
    let _s3 = S3.getInstance()
    await _s3.deleteObj(imageMeta.edit.csvPath)
    await _s3.deleteObj(imageMeta.edit.imagePath)
  }

  static async listPublicImageFolder(prefix) {
    let _s3 = S3.getInstance()
    let ret = await _s3.listObjsV2(prefix, '', 1000)
    let dirContents = ret
    // Remove labels file, directories
    dirContents = dirContents.filter((el)=>{
      if (prefix === el.Key) {
        return false
      }
      if (el.Key.slice(-1) === '/') {
        return false
      }
      if (el.Key.endsWith("__LABELS__")) {
        return false
      }
      return true
    })

    dirContents = dirContents.map((el)=>{
      el.Key = el.Key.split('/').splice(1,).join('/')
      return el.Key
    })
    return dirContents
  }

  static async listPrivateImageFolder(prefix) {
    let _s3 = S3.getInstance()
    let ret = await _s3.listObjsV2(prefix, '', 1000)
    let dirContents = ret

    // Remove labels file, directories
    dirContents = dirContents.filter((el)=>{
      if (prefix === el.Key) {
        return false
      }
      if (el.Key.slice(-1) === '/') {
        return false
      }
      if (el.Key.endsWith("__LABELS__")) {
        return false
      }
      return true
    })

    dirContents = dirContents.map((el)=>{
      el.Key = el.Key.split('/').splice(2,).join('/')
      return el.Key
    })

    return dirContents
  }
}