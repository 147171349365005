export const RED   =  'rgba(255, 0,   0,      '
export const BLUE  =  'rgba(28  , 153,   255, '
export const GREEN =  'rgba(0  , 209, 55,     '
export const YELLOW = 'rgba(255, 255,  0,     '
export const CYAN  =  'rgba(0  , 255, 255,    '
export const PINK  =  'rgba(255, 0  , 255,    '
export const OPACITY_LIGHT = '.20 )'
export const OPACITY_100    = '100)'

export const COLOR_LIST = [
  RED + OPACITY_LIGHT,
  BLUE + OPACITY_LIGHT,
  GREEN + OPACITY_LIGHT,
  YELLOW + OPACITY_LIGHT,
  CYAN + OPACITY_LIGHT,
  PINK + OPACITY_LIGHT
]
export const STROKE_LIST = [
  RED + OPACITY_100,
  BLUE + OPACITY_100,
  GREEN + OPACITY_100,
  YELLOW + OPACITY_100,
  CYAN + OPACITY_100,
  PINK + OPACITY_100
]
