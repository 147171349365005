
import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';

let awsUser = null;

const REGION = 'us-west-2'
const IDENTITY_POOL_ID = 'us-west-2:f54785ff-b5fd-4d7c-b6d7-eda39ce14a32'

export default class CognitoUser {
  constructor(user) {
    AWS.config = this._generate_config(user)
    this._test_login()
  } 

  static getInstance(user) {
    if (user === undefined && awsUser === null) {
      return null
    }
    if (awsUser === null) {
      awsUser = new CognitoUser(user)
    }
    return awsUser
  }

  static async signIn(username, password) {
    CognitoUser.freeUser()
    const userCreds = await Auth.signIn(username, password)
    CognitoUser.getInstance(userCreds)
    return userCreds 
  }

  static freeUser() {
    awsUser = null
  }

  _test_login(callback) {
    AWS.config.getCredentials(function(err) {
      if (err) {
        console.log(err, err.stack);
        if (callback) {
          callback(true)
        }
      } else {
        //console.log("AWS Login successful:", AWS.config.credentials);
        return
      }
    })
  }

  _generate_config(user) {
    let cognitoUserPoolUrl = 'cognito-idp.us-west-2.amazonaws.com/' +
      user.pool.userPoolId
    let credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IDENTITY_POOL_ID,
      Logins: {
        [cognitoUserPoolUrl]: user.signInUserSession.idToken.jwtToken 
      },
      LoginId: user.username
    });
    let config = new AWS.Config({
      credentials: credentials, region: REGION
    })
    return config
  }

  refresh() {
    AWS.config.credentials.refresh()
  }
  getIdentityId() {
    return AWS.config.credentials._identityId
  }
  getIdentityPoolId() {
    return AWS.config.credentials.params.IdentityPoolId
  }
  getUsername() {
    return AWS.config.credentials.params.LoginId
  }
  getAccessKeyId() {
    return AWS.config.credentials.data.Credentials.AccessKeyId
  }
  getSecretKey() {
    return AWS.config.credentials.data.Credentials.SecretKey
  }

}