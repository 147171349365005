/**
 * BoxPane component
 * @author Jeremy Aftem
 */

import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './BoxPane.css';
import { Form, Button, FormControl, FormGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import BoxAttributes from './boxattributes/BoxAttributes';
import Annotation from '../util/Annotation';
import CognitoUser from '../../../../../utils/aws/cognito.js';
import AnnotationMngr from '../../../../../utils/aws/annotations/annotationMngr';

const BoxPane = props => {
  /*---------------------------------------------------------------------------
  * State
  *--------------------------------------------------------------------------*/
  const [boxes, setBoxes] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [classes, setClasses] = useState([]);
  const [enable, setEnable] = useState(false);
  const loc = useLocation();

  /*---------------------------------------------------------------------------
  * Hooks
  *--------------------------------------------------------------------------*/
 
  useEffect(() => {
    if (props.classes != null) {
      setClasses(props.classes)
    }
    if (props.boxes != null) { 
      setBoxes(props.boxes)  
      congrueAnnotations(props.boxes)
    } else {
      setBoxes([])
    }
    if (props.enable != null) {
      setEnable(props.enable)
    }
  }, [props])

  useEffect(() => {
    if (props.boxes != null) {
      setBoxes(props.boxes)
      congrueAnnotations(props.boxes)
    }
  }, [props.boxes])

  useEffect(() => {
    removeDuplicateAnnotations(props.prevAnnotations)
  }, [props.prevAnnotations])

  useEffect(()=>{
  }, [annotations])

  /*---------------------------------------------------------------------------
  * Helper func.
  *--------------------------------------------------------------------------*/

  function removeDuplicateAnnotations(prevAnnotations) {
    if (prevAnnotations.length > 0) {
      let newAnnotations = annotations.filter((el)=>{
        let duplicate = false
        let uuid = el.getUuid()
        prevAnnotations.forEach((pel)=>{
          if (uuid == pel.getUuid()) {
            duplicate = true
          }
        })
        return !duplicate // Return no duplicate annots
      })
      // Push prevAnnots
      prevAnnotations.forEach((el)=>{
        newAnnotations.push(el)
      })
      setAnnotations(newAnnotations)
    }
  }

  /* Update BoxPane's annotations corresponding to changes done in Fabric canvas
    */
  function congrueAnnotations(incoming_boxes) {
    if (incoming_boxes.length == 0) {
      setAnnotations([])
      return
    } else {
      // Remove annotations that correspond to boxes that were deleted in Fabric.
      removeDeletedAnnotations(incoming_boxes)
      // Create new annotations from newly created incoming boxes.
      createMissingAnnotations(incoming_boxes)
      // Update annotation coords from new/edited Fabric boxes.
      updateAnnotationCoords(incoming_boxes)
      // Set annotations.
      setAnnotations([...annotations])
    }
    return
  }

  function removeDeletedAnnotations(boxes) {
    annotations.forEach((annot)=>{
      if (boxes.findIndex((box)=>(box.rect.uuid == annot.uuid))==-1) {
        let _idx = annotations.indexOf(annot)
        annotations.splice(_idx, 1)
      }
    })
    return
  }

  function createMissingAnnotations(incoming_boxes) {
    incoming_boxes.forEach((box)=>{
      if (findAnnotationIndex(box.rect.uuid) == -1) {
        let a = Annotation.buildFromBbox(box)
        annotations.push(a)
      }
    })
  }

  function updateAnnotationCoords(incoming_boxes) {
    incoming_boxes.forEach((box)=>{
      annotations.forEach((annot)=>{
        if (annot.getUuid() == box.rect.uuid) {
          annot.updateFromBbox(box)
        }
      })
    })
    return
  }

  function updateAnnotation(_annotation) {
    let flag = false
    let new_annotations = annotations.map((annot)=>{
      if (annot.getUuid() == _annotation.getUuid()) {
        return _annotation
      } else {
        return annot
      }
    })
    setAnnotations([...new_annotations])
  }

  function findAnnotationIndex(uuid) {
    return annotations.findIndex((annot)=>(annot.getUuid() == uuid))
  }

  function prepareAnnotationsForSave() {
    let _temp = props.imageMeta.path.split('/')
    let imageId = _temp[_temp.length-1]
    let status = true
    annotations.forEach((el)=>{
      // Set imageID and source (username)
      el.prepareForSave(imageId, loc.state.user.username)
      if (el.getClass().toUpperCase() == 'BACKGROUND') {
        status = false
      }
    })
    return status
  }

  function generateAnnotationFile() {
    let annotStr = ''
    annotations.forEach((el)=>{
      annotStr += el.getStr() + '\n'
    })
    return annotStr
  }

  async function handleReport(e) {
    e.preventDefault()
    if (window.confirm(
      'Are you sure you want to report this item?\n\n'+
      'Reporting this item will remove the image from your workspace and you '+
      'will no longer be able to annotate it.'
      )) {
      await AnnotationMngr.reportImage(
        props.imageMeta
      )
      props.teardown(props.imageMeta)
    }
  }

  async function handleDelete(e) {
    e.preventDefault()
    if (window.confirm(
      'Are you sure you want to delete this annotation?\n\n'+
      'Deleting this item will remove the annotation from the saved pane and '+
      'return the image to the \'To Annotate\' pane.'
    )) {
      await AnnotationMngr.deleteAnnotation(
        props.imageMeta
      )
      props.imageMeta.deleted = true
      props.teardown(props.imageMeta)
    }
  }

  async function handleSaveAnnotation(e) {
    e.preventDefault()
    prepareAnnotationsForSave()
    let author = props.imageMeta.author
    await AnnotationMngr.saveAnnotations(
      author,
      props.imageMeta.path,
      annotations
    )
    if (!isAdmin(author.username)) {
      copyMetaFiles(props.imageMeta.path)
    }
    props.imageMeta.deleted = false
    // Teardown (remove image from select)
    props.teardown(props.imageMeta)
  }

  /**
   * Is the author of the annotation the currently logged in user. 
   * @param {str} username 
   */
  function isAdmin(username){
    return username != CognitoUser.getInstance().getUsername()
  }

  async function copyMetaFiles(path) {
    // Copy image to user directory.
    await AnnotationMngr.copyImageToUserDir(path)
    // Copy labels file if not already done.
    let public_labels_path = path.split('/').slice(0,-1).join('/')
    public_labels_path = public_labels_path + '/__LABELS__'
    await AnnotationMngr.copyLabelsToUserDir(public_labels_path)
  }

  /*---------------------------------------------------------------------------
  * Return 
  *--------------------------------------------------------------------------*/

  function render() {
    if (!enable && !props.imageMeta) {
      return (
        <div />
      )
    } else {
      return (
        <div className="Attributes-panes">
          <div>
            Image
          </div>
          <div className="imageMeta-panes">
            {props.imageMeta && props.imageMeta.path}
          </div>
          <div className="Delete-pane">
            { props.imageMeta &&
              (!props.imageMeta.isEdit ? (
                <Button
                  id='remove-image-btn'
                  variant="warning"
                  onClick={handleReport}>
                    Report Image
                </Button>
              ) : (
                <Button
                  id='remove-image-btn'
                  variant='danger'
                  onClick={handleDelete}>
                    Delete Annotation
                </Button>
              ))
            }
          </div>
          <div className="Annotations-panes">
            Annotations
          </div>
          <div className="Attr-panes">
            {boxes.map((box, index)=> {
              let idx = findAnnotationIndex(box.rect.uuid)
              let annot = annotations[idx]
              return (
                <BoxAttributes
                  index={index}
                  uuid={box.rect.uuid}
                  key={box.rect.uuid} 
                  box={box}
                  annotation={annot}
                  updateAnnotation={updateAnnotation}
                  labels={props.labels}
                />
            )})
            }
          </div>
          {
            boxes.length > 0 ? (
            <div className="Save-pane">
              <Button
                id='save-annots-btn'
                variant="info"
                onClick={handleSaveAnnotation}>
                Save Annotations
              </Button>
            </div>
          ) : (<div />)
          }

        </div>
      )
    }
  }
  return render()
}
export default BoxPane;