/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:f54785ff-b5fd-4d7c-b6d7-eda39ce14a32",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_j8gE1e8Vi",
    "aws_user_pools_web_client_id": "6o2v0en9d5q5vlal5kp5u127di",
    "oauth": {},
    "aws_user_files_s3_bucket": "cvision-annotations215421-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
