/**
 * Workspace component
 * @author Jeremy Aftem
 * 
 */

import React, { useState, useEffect } from 'react';
import '../Workspace.css';
import { useLocation } from 'react-router-dom'
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ampS3 from '../../../../utils/aws/amplify/ampS3';
import S3 from '../../../../utils/aws/s3';
import AWS from 'aws-sdk';
import AnnotationMngr from '../../../../utils/aws/annotations/annotationMngr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFolder,
  faFolderOpen,
  faBook,
  faBookOpen } from '@fortawesome/free-solid-svg-icons'
import CognitoUser from '../../../../utils/aws/cognito';

const UserPanel = props => {
  /*---------------------------------------------------------------------------
  * State
  *--------------------------------------------------------------------------*/
  /* Dirs */
  const [ dirs, setDirs ] = useState([])
  const [ selectedDir, setSelectedDir ] = useState(null)
  const [ files, setFiles ] = useState([])

  /* Finished */
  const [ edit, setEdit ] = useState([])
  const [ editSelect, setEditSelect ] = useState(undefined)
  const [ displayEdit, setDisplayEdit ] = useState(false)
  const [ annotationStrs, setAnnotationStrs ] = useState(null)
  /* Todo */
  const [ displayTodo, setDisplayTodo ] = useState(true)
  const [ todoSelect, setTodoSelect ] = useState(undefined)
  /* Labels */
  const [ labels, setLabels ] = useState([])
  /* Image meta data */
  const [ imageMeta, setImageMeta ] = useState(null)
  /* Location */
  const loc = useLocation()

  /*---------------------------------------------------------------------------
  * Hooks
  *--------------------------------------------------------------------------*/
  useEffect(()=>{
    listDirectories()
    let user = loc.state.user
    if (user === undefined) return;
    let _s3 = S3.getInstance(user)
    AWS.config.credentials.refresh()
  }, [])

  useEffect(()=>{
    listFiles(selectedDir)
  }, [selectedDir])

  useEffect(()=>{
    updateEdit(editSelect)
  }, [editSelect])

  useEffect(()=>{
    props.setImageMeta(imageMeta)
  }, [imageMeta])

  useEffect(()=>{
    props.setLabels(labels)
  }, [labels])

  useEffect(()=>{
    props.setAnnotationStrs(annotationStrs)
  }, [annotationStrs])

  useEffect(()=>{
    if (props.teardownFile !== null) {
      annotationTeardown(props.teardownFile)
    }
  }, [props.teardownFile])

  /*---------------------------------------------------------------------------
  * Helper functions
  *--------------------------------------------------------------------------*/

  async function updateEdit(annotationPath) {
    if (annotationPath == undefined) return;
    // Grab file annotations.
    let imagePath = getImagePathFromAnnotationPath(annotationPath)
    let annots = await AnnotationMngr.getCsv(annotationPath)
    let _s3 = new ampS3()
    imagePath = getPublicImagePath(imagePath)
    let imageData = await _s3.getFile(imagePath)
    setAnnotationStrs(annots)
    let isEdit = true
    updateImageMeta(imagePath, imageData, isEdit)
    setTodoSelect(undefined)
  }
  
  /* Get path of image that corresponds with annotation file. */
  function getImagePathFromAnnotationPath(path) {
    path = path.split('/')
    path.splice(path.length - 1, 0, 'train')
    path = path.join('/')
    path = path.split('.').slice(0,-1).join('.')
    return path
  }

  /**
    Get path of public image from private image path. That is, remove the user-
    name and 'train' directories from the path.
    eg. username/raw/directory/train/image.jpg ->
        raw/directory/image.jpg
   */
  function getPublicImagePath(privatePath) {
    let publicPath = privatePath.split('/')
    publicPath.splice(-2,1)
    publicPath.splice(0,1)
    publicPath = publicPath.join('/')
    return publicPath
  }

  async function listDirectories() {
    let _s3 = S3.getInstance(loc.state.user)
    let ret = await _s3.listObjs('public/raw/', '/')
    let _dirs = ret.CommonPrefixes.map((el)=>el.Prefix.slice(7,))
    setDirs(_dirs)
  }

  async function listFiles(dir) {
    if (dir == null) { return }
    let username = loc.state.user.username
    let identityId = CognitoUser.getInstance().getIdentityId()
    await updateLabels(dir)
    let _public_files = await AnnotationMngr.listPublicImageFolder('public/' + dir)
    let path = 'private/' + identityId + '/' + username + '/' + dir
    let _private_files = await AnnotationMngr.listPrivateImageFolder(path)
    let _files = filterEditFiles(_private_files, _public_files)
    setFiles(_files)
    listEditFiles(dir, _private_files)
  }

  async function updateLabels(dir) {
    let s3 = new ampS3()
    let filepath = dir + '__LABELS__'
    let labels = await s3.getText(filepath, 'public')
    labels = labels.split('\n').filter((el)=>el != '')
    setLabels(labels)
  }

  async function listEditFiles(dir, private_files=null) {
    let username = loc.state.user.username
    let identityId = CognitoUser.getInstance().getIdentityId()
   if (private_files == null) {
     let dirPath = 'private/' + identityId + '/' + username + '/' + dir
     let private_files = await AnnotationMngr.listPrivateImageFolder(dirPath)
     private_files = filterForCsv(private_files)
     setEdit(private_files)
   } else {
     private_files = filterForCsv(private_files)
     setEdit(private_files)
   }
  }

  /* Remove file names in privatef from publicf */
  function filterEditFiles(privatef, publicf) {
    let usernameLen = loc.state.user.username.length
    privatef.forEach((el) => {
      let fn = el.slice(usernameLen+1,-4)
      let idx = publicf.indexOf(fn)
      if (idx !== -1) {
        publicf.splice(idx, 1)
      }
    })
    return publicf
  }
  function filterForCsv(privatef) {
    return privatef.filter((el)=>{
      return el.endsWith('.csv')
    })
  }

  function updateSelectedDir(e) {
    setSelectedDir(e.target.value)
    setDisplayTodo(true)
    setImageMeta(null)
  }

  function annotationTeardown(imageMeta) {
    if (imageMeta == null) return
    if (isImageInSavedPane(imageMeta)) {
      savedPaneTeardown(imageMeta)
    } else if (isImageInAnnotatePane(imageMeta)) {
      annotatePaneTeardown(imageMeta)
    }
  }

  /* */
  function savedPaneTeardown(imageMeta) {
    let idx = getEditIdx(imageMeta)
    /* If user deleted this annotation */
    if (imageMeta.deleted) {
      edit.splice(idx, 1)
      listFiles(selectedDir)
      if (edit.length > 0) {
        // Move to next saved file.
        let nextFile = edit[idx]
        setEditSelect(nextFile)
      } else {
        // If no other saved files exist after delete.
        // Reset canvas.
        setEditSelect(undefined)
        setAnnotationStrs(null)
        setImageMeta(null)
      }
    /* If user editted the annotation */
    } else {
      updateEdit(edit[idx]) 
    }
  }

  /* Remove image from pane and open next image */
  function annotatePaneTeardown(imageMeta) {
    let idx = files.indexOf(imageMeta.path)
    files.splice(idx, 1)
    setFiles([...files])
    forceSelectImage(idx)
    listEditFiles(selectedDir)
  }

  function getCsvPathOfImageMeta(imageMeta) {
    let username = loc.state.user.username
    return username + '/' + imageMeta.path + '.csv'
  }

  function getEditIdx(imageMeta) {
    let path = getCsvPathOfImageMeta(imageMeta)
    return edit.indexOf(path)
  }

  function isImageInSavedPane(imageMeta) {
    /*
      The edit folders path format is {username}/raw/...
    */
    let path = getCsvPathOfImageMeta(imageMeta)
    if (edit.indexOf(path) == -1) {
      return false
    } else {
      return true
    }
  }

  function isImageInAnnotatePane(imageMeta) {
    if (files.indexOf(imageMeta.path) == -1) {
      return false
    } else {
      return true
    }
  }

  async function forceSelectImage(idx) {
    if (idx == files.length) {
      idx = files.length - 1
    }
    if (files.length > 0) {
      let file = files[idx]
      let s3 = new ampS3()
      let imageData = await s3.getFile(file)
      let isEdit = false 
      updateImageMeta(file, imageData, isEdit)
      setTodoSelect(file)
    }
  }
  async function forceSelectEdit(idx) {
    if (idx == edit.length) {
      idx = edit.length - 1
    }
    if (edit.length > 0) {
      let _edit = edit[idx]
      _handleEditSelect(_edit) 
    }
  }

  /*---------------------------------------------------------------------------
  * Handlers
  *--------------------------------------------------------------------------*/

  function updateImageMeta(file, imageData, isEdit) {
    let _imageMeta = {
      path: file,
      data: imageData,
      author: {
        username: CognitoUser.getInstance().getUsername(),
        id: CognitoUser.getInstance().getIdentityId()
      },
      isEdit: isEdit
    }
    if (isEdit) {
      _imageMeta = generateEditMeta(_imageMeta)
    }
    setImageMeta(_imageMeta)
  }

  function generateEditMeta(imageMeta) {
    let author = imageMeta.author
    let filePrefix = 'private/' + author.id + '/' + author.username + '/'
    let csvPath = filePrefix + imageMeta.path + '.csv'
    let imagePath = csvPath.split('/')
    imagePath.splice(5,0,'train')
    imagePath = imagePath.join('/')
    imagePath = imagePath.slice(0,-4)
    imageMeta.edit = {
      csvPath,
      imagePath
    }
    return imageMeta
  }

  function updateSelectedDir(e) {
    setSelectedDir(e.target.value)
    setDisplayTodo(true)
    setImageMeta(null)
  }

  async function handleSelectImage(event) {
    setTodoSelect(event.target.value)
    let file = event.target.value
    let s3 = new ampS3()
    let imageData = await s3.getFile(file)
    setAnnotationStrs([])
    let isEdit = false
    updateImageMeta(file, imageData, isEdit)
    setEditSelect(undefined)
  }

  async function _handleEditSelect(fn) {
    // Update select.
    setEditSelect(fn)
    // Grab file annotations.
    let annots = await AnnotationMngr.getCsv(fn)
    // Get path of image that corresponds with annotation file.
    // Insert 'train'
    fn = fn.split('/')
    fn.splice(fn.length-1, 0, 'train')
    fn = fn.join('/')
    // Remove last extension (csv)
    fn = fn.split('.').slice(0,-1).join('.')    
    // Grab image.
    let _s3 = new ampS3()
    let origFile = fn.split('/')
    origFile.splice(-2, 1)
    origFile.splice(0,1)
    origFile = origFile.join('/')

    let imageData = await _s3.getFile(origFile)

    setAnnotationStrs(annots)
    let isEdit = true
    updateImageMeta(origFile, imageData, isEdit)
    setTodoSelect(undefined) 
  }

  async function handleEditSelect(e) {
    let fn = e.target.value
    setEditSelect(fn)
    //_handleEditSelect(fn)
  }

  function handleClickEdit(e) {
    setDisplayEdit(!displayEdit)
  }

  function handleClickTodo(e) {
    setDisplayTodo(!displayTodo)
  }


  /*---------------------------------------------------------------------------
  * Render
  *--------------------------------------------------------------------------*/
  
  return (
    <div className="Workspace-image-selector">
      <p id="folder-selector-title">
        Directory
      </p>
      <Form id="folder-selector">
        <Form.Control id="folder-selector" as="select" onChange={updateSelectedDir} multiple>
          {dirs.map((dir, index)=>{
            return (<option key={index}>{dir}</option>)
            })
          }
        </Form.Control>
      </Form>
      <div id="edit-selector-title" onClick={handleClickEdit}>
        <FontAwesomeIcon icon={displayEdit ? faFolderOpen : faFolder} color={displayEdit ? '#efc062' : '#FFFFFF'}/>
        <div className="edit-gen-title">Saved ({edit.length})</div>
      </div>
      {
        selectedDir == null && displayEdit == true ? (<div className="edit-help">Select a directory above.</div>) : null
      }
      {
        selectedDir != null && displayEdit == true && edit.length == 0 ? (<div className="edit-help">Directory empty.</div>) : null
      }
      {
        selectedDir != null && displayEdit == true ? (
        <Form id="edit-selector">
          
            <Form.Control multiple id="edit-selector" as="select" value={[editSelect]} onChange={handleEditSelect}>
              {
                edit.map((file, index)=>{
                  let _sp = file.split('/')
                  let _fn = _sp[_sp.length-1]
                  return (<option key={index} value={file}>{_fn}</option>)
                })
              }
            </Form.Control>
        </Form>
        ) : null
      }
      <div id="image-selector-title" onClick={handleClickTodo}>

        <FontAwesomeIcon icon={displayTodo ? faBookOpen : faBook} color={displayTodo ? '#ea7e73' : '#FFFFFF'} />
          
        <div className="todo-gen-title">To annotate ({files.length})</div>
      </div>
      {
        selectedDir == null && displayTodo == true ? (<div className="image-selector-help">Select a directory above.</div>) : null
      }
      {
        selectedDir != null && displayTodo == true && files.length == 0 ? (<div className="image-selector-help">Directory empty.</div>) : null
      }
      {
        selectedDir != null && displayTodo == true ? (
        <Form id="image-selector">
          <Form.Control onChange={handleSelectImage} id="image-selector" as="select" value={[todoSelect]} multiple>
            {
              files.map((file, index)=>{
                let _sp = file.split('/')
                let _fn = _sp[_sp.length-1]
                return (<option key={index} value={file}>{_fn}</option>)
              })
            }
          </Form.Control>
        </Form>
        ) : null
      }

    </div>
  )
 
}

export default UserPanel;