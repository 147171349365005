
export default class Annotation {
  constructor() {
    this.str = ''
    this.uuid = ''
    this.params = undefined
    this.setDefaults()
    return
  }
  
  setDefaults() {
    this.imageId = ''
    this.source = ''
    this.labelName = 0
    this.confidence = 1
    this.xmin = ''
    this.ymin = ''
    this.xmax = ''
    this.ymax = ''
    this.isOccluded = false
    this.isDepiction = false
    this.isGroupOf = false
    this.isInside = false
    this.isTruncated = false
    this.className = 'Background'
  }

  static buildFromStr(str, uuid) {
    let annotObj = new Annotation()
    annotObj._buildFromStr(str, uuid)
    return annotObj
  }

  static buildFromBbox(bbox) {
    let annotObj = new Annotation()
    annotObj.setDefaults()
    annotObj.setUuid(bbox.rect.uuid)
    annotObj.setXmin(bbox.xmin)
    annotObj.setYmin(bbox.ymin)
    annotObj.setXmax(bbox.xmax)
    annotObj.setYmax(bbox.ymax)
    return annotObj
  }


  _buildFromStr(str, uuid) {
    this.uuid = uuid
    this.str = str
    this.params = str.split(',')
    this.setAttributes(this.str)
  }

  updateFromBbox(bbox) {
    this.setXmin(bbox.xmin)
    this.setYmin(bbox.ymin)
    this.setXmax(bbox.xmax)
    this.setYmax(bbox.ymax)
  }

  getUuid() {
    return this.uuid
  }
  setUuid(uuid) {
    this.uuid = uuid
  }

  setAttributes(str) {
    this.str = str
    this.imageId = this.params[0]
    this.source = this.params[1]
    this.labelName = this.params[2]
    this.confidence = 1
    this.xmin = this.params[4]
    this.xmax = this.params[5]
    this.ymin = this.params[6]
    this.ymax = this.params[7]
    this.isOccluded = (this.params[8] == '1')
    this.isTruncated = (this.params[9] == '1')
    this.isGroupOf = (this.params[10] == '1')
    this.isDepiction = (this.params[11] == '1')
    this.isInside = (this.params[12] == '1')
    this.id = 1 // TODO What is this?
    this.className = this.params[14]
  }

  updateStr() {
    let str = ''
    let comma = ','
    str += this.imageId + comma                                                   // 0
    str += this.source + comma                                                    // 1
    str += this.labelName + comma                                                 // 2 
    str += 1 + comma                                                              // 3
    str += this.xmin.toString() + comma                                           // 4
    str += this.xmax.toString() + comma                                           // 5
    str += this.ymin.toString() + comma                                           // 6
    str += this.ymax.toString() + comma                                           // 7
    str += (this.isOccluded ? '1' : '0') + comma                                  // 8
    str += (this.isTruncated ? '1' : '0') + comma                                 // 9
    str += (this.isGroupOf ? '1' : '0') + comma                                   // 10
    str += (this.isDepiction ? '1' : '0') + comma                                 // 11
    str += (this.isInside ? '1' : '0') + comma                                    // 12
    str += '1' + comma                                                            // 13
    str += this.className
    this.str = str
  }

  prepareForSave(imageId, source) {
    this.setImageId(imageId)
    this.setSource(source)
    this.fitCoordsIntoImage()
  }

  fitCoordsIntoImage() {
    this.xmin = this.xmin < 0 ? 0 : this.xmin
    this.xmin = this.xmin > 1 ? 1 : this.xmin

    this.ymin = this.ymin < 0 ? 0 : this.ymin
    this.ymin = this.ymin > 1 ? 1 : this.ymin

    this.xmax = this.xmax < 0 ? 0 : this.xmax
    this.xmax = this.xmax > 1 ? 1 : this.xmax

    this.ymax = this.ymax < 0 ? 0 : this.ymax
    this.ymax = this.ymax > 1 ? 1 : this.ymax
    this.updateStr()
  }

  setStr(str) {
    this.str = str
    this.setAttributes(str)
  }

  getStr() {
    this.updateStr()
    return this.str
  }
  getImageId() {
    return this.imageId
  }
  getSource() {
    return this.source
  }
  getXmin() {
    return this.xmin
  }
  getYmin() {
    return this.ymin
  }
  getXmax() {
    return this.xmax
  }
  getYmax() {
    return this.ymax
  }
  getIsOccluded() {
    return this.isOccluded
  }
  getIsTruncated() {
    return this.isTruncated
  }
  getIsInside() {
    return this.isInside
  }
  getIsGroupOf() {
    return this.isGroupOf
  }
  getIsDepiction() {
    return this.isDepiction
  }
  getId() {
    return this.id
  }
  getClass() {
    return this.className
  }
  /* */

  setLabelName(labelName) {
    this.labelName = labelName 
    this.updateStr()
  }

  setImageId(imageId) {
    this.imageId = imageId
    this.updateStr()
  }
  setSource(source) {
    this.source = source
    this.updateStr()
  }
  setXmin(xmin) {
    this.xmin = xmin
    this.updateStr()
  }
  setYmin(ymin) {
    this.ymin = ymin
    this.updateStr()
  }
  setXmax(xmax) {
    this.xmax = xmax
    this.updateStr()
  }
  setYmax(ymax) {
    this.ymax = ymax
    this.updateStr()
  }
  setIsOccluded(isOccluded) {
    this.isOccluded = isOccluded
    this.updateStr()
  }
  setIsTruncated(isTruncated) {
    this.isTruncated = isTruncated
    this.updateStr() 
  }
  setIsInside(isInside) {
    this.isInside = isInside
    this.updateStr()
  }
  setIsGroupOf(isGroupOf) {
    this.isGroupOf = isGroupOf
    this.updateStr()
  }
  setIsDepiction(isDepiction) {
    this.isDepiction = isDepiction
    this.updateStr()
  }
  setId(id) {
    this.id = id
    this.updateStr()
  }
  setClassName(className) {
    this.className = className
    this.updateStr()
  }
}