/**
 * Workspace component
 * @author Jeremy Aftem
 * 
 */

import React, { useState, useEffect } from 'react';
import '../Workspace.css';
import { useLocation } from 'react-router-dom'
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ampS3 from '../../../../utils/aws/amplify/ampS3';
import S3 from '../../../../utils/aws/s3';
import AWS from 'aws-sdk';
import AnnotationMngr from '../../../../utils/aws/annotations/annotationMngr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFolder,
  faFolderOpen } from '@fortawesome/free-solid-svg-icons'

const UserPanel = props => {
  /*---------------------------------------------------------------------------
  * State
  *--------------------------------------------------------------------------*/
  const [ users, setUsers ] = useState([])
  const [ selectedUser, setSelectedUser ] = useState(null)
  const [ author, setAuthor ] = useState(null)
  /* Dirs */
  const [ dirs, setDirs ] = useState([])
  const [ selectedDir, setSelectedDir ] = useState(null)
  const [ files, setFiles ] = useState([])
  /* Finished */
  const [ editSelect, setEditSelect ] = useState(undefined)
  const [ displayEdit, setDisplayEdit ] = useState(false)
  const [ annotationStrs, setAnnotationStrs ] = useState(null)
  /* Todo */
  const [ displayTodo, setDisplayTodo ] = useState(true)
  /* Labels */
  const [ labels, setLabels ] = useState([])

  const [ imageMeta, setImageMeta ] = useState(null)
  /* Location */
  const loc = useLocation()

  /*---------------------------------------------------------------------------
  * Hooks
  *--------------------------------------------------------------------------*/
  useEffect(()=>{
    let user = loc.state.user
    if (user === undefined) return;
    let _s3 = S3.getInstance(user)
    AWS.config.credentials.refresh()
    listUsers()
  }, [])

  useEffect(()=>{
    listDirectories()
  }, [selectedUser])

  useEffect(()=>{
  }, [author])

  useEffect(()=>{
    listFiles(selectedDir)
  }, [selectedDir])

  useEffect(()=>{
    props.setLabels([...labels])
  }, [labels])

  useEffect(()=>{
    props.setAnnotationStrs(annotationStrs)
  }, [annotationStrs])

  useEffect(()=>{
    props.setImageMeta(imageMeta)
  }, [imageMeta])

  useEffect(()=>{
    if (props.teardownFile !== null) {
      annotationTeardown(props.teardownFile)
    }
  }, [props.teardownFile])

  /*---------------------------------------------------------------------------
  * Helper functions
  *--------------------------------------------------------------------------*/
  async function listDirectories() {
    if (selectedUser != null) {
      let _dirs = await AnnotationMngr.listDirsOfUser(selectedUser)
      setDirs(_dirs)
    }
  }

  async function listUsers() {
    let _users = await AnnotationMngr.listUsers()
    setUsers(_users)
  }

  async function listFiles(dir) {
    if (dir == null) { return }
    let _files = await AnnotationMngr.listFiles(dir.path, '/')
    _files = _files.map((el)=>{
      return {
        path: el,
        display: el.split('/').slice(-1,).join('/')
      }
    })
    setFiles(_files)
    let publicPath = dir.path.split('/').slice(3,).join('/')
    let labelsPath = publicPath + '__LABELS__'
    let labels = await AnnotationMngr.readLabels(labelsPath)
    setLabels(labels)

    setDisplayEdit(true)
  }

  function annotationTeardown(imageMeta) {
    _handleSelectImage(imageMeta.csvPath)
  }


  /*---------------------------------------------------------------------------
  * Handlers
  *--------------------------------------------------------------------------*/

  function updateImageMeta(file, imageData, _author, csvPath) {
    let _imageMeta = {}
    _imageMeta.path = file
    _imageMeta.data = imageData
    _imageMeta.csvPath = csvPath
    if (_author) {
      _imageMeta.author = _author
    } else {
      _imageMeta.author = author
    }
    _imageMeta.isEdit = true
    setImageMeta(_imageMeta)
  }

  function updateSelectedUser(e) {
    let _user = users.filter((el)=>{
      return el.username === e.target.value
    })[0]
    setSelectedUser(_user)
    setImageMeta(null)
    setAnnotationStrs(null)
    setEditSelect(null)
    setSelectedDir(null)
  }

  function updateSelectedDir(e) {
    let dir = dirs.filter((el)=>{
      return el.display === e.target.value
    })[0]
    setSelectedDir(dir)
    setImageMeta(null)
  }

  async function handleSelectImage(event) {
    _handleSelectImage(event.target.value)
  }

  async function _handleSelectImage(filename) {
    setEditSelect(filename)
    let csvPath = filename
    let imagePath = csvPath.split('/').slice(3,).join('/')
    imagePath = imagePath.slice(0,-4)
    
    let _author = {
      username: csvPath.split('/').slice(2,3)[0],
      id: csvPath.split('/').slice(1,2)[0]
    }
    setAuthor(_author)
  
    let _ampS3 = new ampS3()
    let imageData = await _ampS3.getFile(imagePath)
    let annots = await AnnotationMngr.getCsvAsAdmin(csvPath)

    let author = {
      username: csvPath.split('/').slice(2,3)[0],
      id: csvPath.split('/').slice(1,2)[0]
    }
    updateImageMeta(imagePath, imageData, author, csvPath)
    setAnnotationStrs(annots)
  }

  function handleClickEdit(e) {
    setDisplayEdit(!displayEdit)
  }

  function handleClickTodo(e) {
    setDisplayTodo(!displayTodo)
  }


  /*---------------------------------------------------------------------------
  * Render
  *--------------------------------------------------------------------------*/
  
  return (
    <div className="Workspace-image-selector">
      <p id="folder-selector-title">
        Select user
      </p>
      <Form id="folder-selector">
        <Form.Control
          id="folder-selector"
          as="select"
          onChange={updateSelectedUser}
          multiple
        >
          {users.map((user, index)=>{
            return (<option key={index}>{user.username}</option>)
            })
          }
        </Form.Control>
      </Form>

      <p id="folder-selector-title">
        User directories
      </p>
      <Form id="folder-selector">
        <Form.Control
          id="folder-selector"
          as="select"
          onChange={updateSelectedDir}
          value={[selectedDir == null ? null : selectedDir.display]}
          multiple>
          {dirs.map((dir, index)=>{
            return (<option key={dir.display}>{dir.display}</option>)
            })
          }
        </Form.Control>
      </Form>
      <div id="edit-selector-title" onClick={handleClickEdit}>
          <FontAwesomeIcon
            icon={displayEdit ? faFolderOpen : faFolder}
            color={displayEdit ? '#efc062' : '#FFFFFF'}
          />
        <div className="edit-gen-title">Saved ({files.length})</div>
      </div>
      {
        selectedDir == null && displayEdit == true ? (
          <div className="image-selector-help">Select a directory above.</div>
        ) : null
      }
      {
        selectedDir != null && displayEdit == true && files.length == 0 ? (
          <div className="image-selector-help">Directory empty.</div>
        ) : null
      }
      {
        selectedDir != null && displayEdit == true ? (
        <Form id="image-selector">
          <Form.Control
            onChange={handleSelectImage}
            id="image-selector"
            as="select" value={[editSelect]}
            multiple>
            {
              files.map((file, index)=>{
                return (<option key={index} value={file.path}>{file.display}</option>)
              })
            }
          </Form.Control>
        </Form>
        ) : null
      }
  
    </div>
  )
}

export default UserPanel;