/**
 * Workspace component
 * @author Jeremy Aftem
 * 
 */

import React, { useState, useEffect } from 'react';
import './Workspace.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Canvas from './canvas/Canvas';
import UserPanel from './panels/userPanel';
import AdminPanel from './panels/adminPanel';
import { Link, useLocation, Redirect } from 'react-router-dom'
import { CognitoIdentity } from 'aws-sdk';
import CognitoUser from '../../../utils/aws/cognito';
//import awsconfig from '../../../aws-exports';

let isResizing = false 

function Workspace() {
  /*---------------------------------------------------------------------------
  * State
  *--------------------------------------------------------------------------*/

  const [ imageMeta, setImageMeta ] = useState(null)
  const [ annotationStrs, setAnnotationStrs ] = useState(null)
  const [ labels, setLabels ] = useState([])
  const [ teardownFile, setTeardownFile ] =  useState(null)
  const [ menuWidth, setMenuWidth ] = useState(250)
  const [ loginBad, setLoginBad ] = useState(null)
  const loc = useLocation()

  const cbHandleMouseMove = React.useCallback(handleMouseMove, []);
  const cbHandleMouseUp = React.useCallback(handleMouseUp, []);

  const SIDE_MARGIN = 30 // pixels
  const SIDE_MARGIN_px = 30 + 'px'
  
  /*---------------------------------------------------------------------------
  * Hooks
  *--------------------------------------------------------------------------*/

  useEffect(()=>{
    //console.log('annotation str', annotationStrs)
  }, [annotationStrs])

  useEffect(()=>{
    /* Check if login has expired if user tries to use annotator. */
    checkLoginExpired()
  }, [annotationStrs, teardownFile, labels, imageMeta])

 /*---------------------------------------------------------------------------
  * Helper functions
  *--------------------------------------------------------------------------*/
  function annotationTeardown(filename) {
    /* Called when annotation is saved. */
    setTeardownFile(null)     // Force re-render of workspace.
    setTeardownFile(filename)
  }

  function checkLoginExpired() {
    let cognitoUser = CognitoUser.getInstance(loc.state.user)
    cognitoUser._test_login(setLoginBad)
  }

  /*---------------------------------------------------------------------------
  * Handlers
  *--------------------------------------------------------------------------*/

  function handleMouseDown (e) {
    e.stopPropagation();
    e.preventDefault();
    // we will only add listeners when needed, and remove them afterward
    document.addEventListener('mousemove', cbHandleMouseMove);
    document.addEventListener('mouseup', cbHandleMouseUp);
    isResizing = true
  };

  function handleMouseMove (e) {
    if (!isResizing) {
      return;
    }

    let mousePos = e.clientX - SIDE_MARGIN
    let minWidth = 230;
    if (mousePos > minWidth) {
      // using a ref instead of state will be way faster
      //menuPanel.current.style.width = curSize + 'px';
      setMenuWidth(mousePos)
    }
  };

  function handleMouseUp (e) {
    if (!isResizing) {
      return;
    }
    isResizing = false;
    document.removeEventListener('mousemove', cbHandleMouseMove);
    document.removeEventListener('mouseup', cbHandleMouseUp);
  };

 /*---------------------------------------------------------------------------
  * Render
  *--------------------------------------------------------------------------*/
  function render() {
    if (loc.state == undefined || loginBad) {
      return (
        <Redirect to={{ pathname: '/' }} />
      )
    }
    let user = loc.state.user
    let groups = user.signInUserSession.idToken.payload['cognito:groups']
    let group = null
    if (groups != undefined) {
      group = groups[0]
    }
    return (
      <div
        className="Workspace"
        style={{'paddingLeft': SIDE_MARGIN_px, 'paddingRight': SIDE_MARGIN_px}}
      >
        <div className="Workspace-menu">
          <div className="Workspace-panels" style={{'width': menuWidth + 'px'}}>
            { group === 'S3-Admins' ? 
              <AdminPanel
                setImageMeta={setImageMeta}
                setAnnotationStrs={setAnnotationStrs}
                setLabels={setLabels}
                teardownFile={teardownFile}
              /> 
            : 
              <UserPanel
                setImageMeta={setImageMeta}
                setAnnotationStrs={setAnnotationStrs}
                setLabels={setLabels}
                teardownFile={teardownFile}
              />
            }
          </div>
          <div
            className="Workspace-menu-dragger"
            onMouseDown={handleMouseDown}
          >
            &nbsp;
          </div>
        </div>
        <div className="Workspace-canvas">
          <Canvas
            imageMeta={imageMeta}
            annotationStrs={annotationStrs}
            teardown={annotationTeardown}
            labels={labels}
            menuWidth={menuWidth}
          />
      </div>
    </div>
    )
  }

  return render()

}

export default Workspace;