/**
 * Annotator page
 * @author Jeremy Aftem
 * 
 */

import React, { useState, useEffect } from 'react';
import './Annotator.css';
import { Link, useLocation, Redirect } from 'react-router-dom'
import Workspace from './workspace/Workspace';
import { Form, Button, FormControl, FormGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import cube from '../../assets/layout/cube.png';
import useWindowSize from '../common/window/WindowSize';
import CognitoUser from '../../utils/aws/cognito';

const Annotator = props => {
  const [user, setUser] = useState(null)
  const [bodyWidth, setBodyWidth] = useState('100vw')
  const [loginBad, setLoginBad] = useState(false)
  const loc = useLocation()

  function render() {
    if (loc.state != undefined) {
      let cognitoUser = CognitoUser.getInstance(loc.state.user)
      cognitoUser.refresh()
      cognitoUser._test_login(setLoginBad)
    }
    if (loc.state == undefined || loginBad) {
      return (
        <Redirect to={{pathname: '/'}} />
      )
    } else {
      return (
        <div className="Annotator">
          <div className="Annotator-top-bar">
            <div className="Annotator-header" style={{"width": "100vw"}}>
              <div className="Annotator-logo">
                <div><img src={cube} /></div>
                <div className="Annotator-site-name">ai.vitalacy</div>
              </div>
              <div className="Annotator-menu">
                <Button variant="outline-dark" id="help-btn">
                  Guide to annotating
                </Button> 
              </div>
            </div>
          </div>
          <div className="Annotator-body">
            <div className="Annotator-workspace">
              <Workspace />
            </div>
          </div>
        </div>
      )
    }
  }
  return render()
}

export default Annotator;