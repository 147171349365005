/**
 * BoxAttributes component
 * @author Jeremy Aftem
 */

import React, { useState, useEffect, useRef } from 'react';
import './BoxAttributes.css';
import { Form, Button, FormControl, FormGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const BoxAttributes = props => {
  /*---------------------------------------------------------------------------
  * State
  *--------------------------------------------------------------------------*/

  const [annotation, setAnnotation] = useState(null);
  const [labels, setLabels] = useState(['EMPTY']) 

  /*---------------------------------------------------------------------------
  * Hooks
  *--------------------------------------------------------------------------*/
  
  useEffect(() => {
    // If bounding box changes, update annotation str.
    setLabels(props.labels)
    setAnnotationClassDefault(props.annotation, props.labels)
    setAnnotation(props.annotation)
  }, [props])

  useEffect(() => {
  }, [annotation])

  /*---------------------------------------------------------------------------
  * Helper func.
  *--------------------------------------------------------------------------*/

  function setAnnotationClassDefault(annotation, labels) {
    // Since we skip 'Background', we have to default the class as the next
    // class on the labels list.
    if (annotation != null) {
      if (annotation.getClass() == 'Background') {
        annotation.setLabelName(1)
        annotation.setClassName(labels[1])
      }
    }
  }
  function round(number) {
    return Math.round((number*100))/100
  }

  function deep_copy(_obj) {
    return JSON.parse(JSON.stringify(_obj))
  }

  /*---------------------------------------------------------------------------
  * Handlers
  *--------------------------------------------------------------------------*/

  function handleOccluded(e) {
    annotation.setIsOccluded(!annotation.getIsOccluded())
    props.updateAnnotation(annotation)
  }
  function handleTruncated(e) {
    annotation.setIsTruncated(!annotation.getIsTruncated())
    props.updateAnnotation(annotation)
  }
  function handleInside(e) {
    annotation.setIsInside(!annotation.getIsInside())
    props.updateAnnotation(annotation)
  }
  function handleGroupOf(e) {
    annotation.setIsGroupOf(!annotation.getIsGroupOf())
    props.updateAnnotation(annotation)
  }
  function handleDepiction(e) {
    annotation.setIsDepiction(!annotation.getIsDepiction())
    props.updateAnnotation(annotation)
  }
  function handleClass(e) {
    annotation.setClassName(e.target.value)
    let labelName = labels.indexOf(e.target.value)
    annotation.setLabelName(labelName)
    props.updateAnnotation(annotation)
  }

  /*---------------------------------------------------------------------------
  * Return 
  *--------------------------------------------------------------------------*/
  return (
      <div className="Attributes-form">
        <div className="Attributes-form-item">
          <div className="Attribute-name">
            <font color={props.box.color}>Box {props.index}</font>
          </div>
          <div>
            [
              {round(props.box.xmin)},
              {round(props.box.xmax)},
              {round(props.box.ymin)},
              {round(props.box.ymax)}
            ]
          </div>
        </div>
        <div className="Attributes-form-item">
          <div className="Attribute-name">
            Class
          </div>
          <div>
            <select
              value={annotation ? annotation.getClass() : 'Background'}
              name="classes"
              id="classes"
              onChange={handleClass}
              multiple={false}
            >
              {
                labels.map((el, index)=>{
                  if (el.toLowerCase() == 'background') { return null }
                  else {
                    return (
                      <option value={el} key={index}>{el}</option>
                    )
                  }
              })}
            </select>
          </div>
        </div>
        <div className="Attributes-form-item" onClick={handleOccluded}>
          <div className="Attribute-name"> Occluded </div>
          <div>
            <input
              type="checkbox"
              id="occluded"
              name="occluded_check"
              checked={annotation ? annotation.getIsOccluded() : false}
              onChange={()=>{}}
            /> 
          </div>
        </div>
          <div className="Attributes-form-item" onClick={handleTruncated}>
          <div className="Attribute-name"> Truncated </div>
          <div>
            <input
              type="checkbox"
              id="truncated"
              name="truncated_check"
              checked={annotation ? annotation.getIsTruncated() : false}
              onChange={()=>{}}
            /> 
          </div>
        </div>
        <div className="Attributes-form-item" onClick={handleGroupOf}>
          <div className="Attribute-name"> Group Of </div>
          <div>
            <input
              type="checkbox"
              id="groupof"
              name="groupof_check"
              checked={annotation ? annotation.getIsGroupOf() : false}
              onChange={()=>{}}
            /> 
          </div>
        </div>
        <div className="Attributes-form-item" onClick={handleDepiction}>
          <div className="Attribute-name"> Depiction </div>
          <div>
            <input
              type="checkbox"
              id="depiction"
              name="depiction_check"
              checked={annotation ? annotation.getIsDepiction() : false}
              onChange={()=>{}}
            /> 
          </div>
        </div>
        <div className="Attributes-form-item" onClick={handleInside}>
          <div className="Attribute-name"> Inside of </div>
          <div>
            <input
              type="checkbox"
              id="insideof"
              name="insideof_check"
              checked={annotation ? annotation.getIsInside() : false}
              onChange={()=>{}}
            /> 
          </div>
        </div>
      </div>
    )
}
export default BoxAttributes;