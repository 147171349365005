
import Amplify, { Auth, Storage } from 'aws-amplify';

export default class ampS3 {
  constructor() { }

  async getDirs() {
    try {
      let ret = await Storage.list('', {level: 'public'})
      let dirs = ret.filter((el)=>{
        if (el.key.slice(-1) === '/') {
          return true
        }
      })
      return dirs
    } catch (error) {
      return null
    }
  }

  async listDirs(dir) {
    try {
      let ret = await Storage.list(dir, {level: 'public'})
      ret = ret.filter((el)=>{
        if (dir == el.key) {
          return false
        }
        if ((el.key.slice(0,-1).match(/\//g) || []).length > 1) {
          return false
        }
        if (el.key.endsWith("__LABELS__")) {
          return false
        }
        return true
      })
      console.log("RET", ret)
      return ret
    } catch (error) {
      return null
    }
  }

  async listFiles(dir, level) {
    try {
      let ret = await Storage.list(dir, {level})
      ret = ret.filter((el)=>{
        if (dir === el.key) {
          return false
        }
        if (el.key.slice(-1) === '/') {
          return false
        }
        if (el.key.endsWith("__LABELS__")) {
          return false
        }
        return true
      })
      return ret
    } catch (error) {

    }
  }

  async getFile(filepath) {
    try {
      let file = await Storage.get(filepath, {level: 'public'})
      return file
    } catch (error) {
      return null
    }
  }

  async getText(filepath, level) {
    try {
      let file = await Storage.get(filepath, {level: level, download: true})
      let text = await new Response(file.Body).text()
      return text
    } catch (error) {
      return null
    }
  }

  async getCsv(filepath) {
    try {
      let file = await Storage.get(filepath, {level: 'private', download: true})
      let text = await new Response(file.Body).text()
      return text
    } catch (error) {
      return null
    }
  }

  getKeys(_dict) {
    return _dict.map((el)=>{return el.key})
  }

  async saveFile(filepath, data) {
    try {
      let res = await Storage.put(filepath, data, {
        level: 'private',
        contentType: 'text/plain',
        progressCallback(progress) {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        }
      })
      return res
    } catch (error) {
      console.log(error)
      return null
    }
  }

  async deleteFile(filepath) {
    try {
      let res = await Storage.remove(filepath, { level : 'private' })
      return res
    } catch (error) {
      return null
    }
  }
}