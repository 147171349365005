/**
 * Login page
 * @author Jeremy Aftem
 * 
 */

import React, { useState, useEffect } from 'react';
import './Login.css';
import { Link, Redirect, useLocation } from 'react-router-dom'
import { Form, Button, FormControl, FormGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import cube from '../../assets/layout/cube.png';
import { Auth } from 'aws-amplify';
import CognitoUser from '../../utils/aws/cognito';

const Login = props => {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [userCreds, setUserCreds] = useState(null);
  const loc = useLocation()

  async function _login(e) {
    e.preventDefault();  // Do not refresh page.
    try {
      const creds = await CognitoUser.signIn(username, password)
      setUserCreds(creds)
    } catch (error) {
      console.log(error)
      handleError(error)
    }
  }

  function handleError(error) {
    setErrorMsg('Login failed. Please try again.')
  }
  function updateUsername(e) {
    setUsername(e.target.value)
  }

  function updatePassword(e) {
    setPassword(e.target.value)
  }
  function deep_copy(obj) {
    return JSON.parse(JSON.stringify(obj))
  }
  function render() {
    if (userCreds != null) {
      return (
        <Redirect to={{pathname: '/annotate', state: {user: deep_copy(userCreds) }}} push={true} />
      )
    } else {
      return (
        <div className="Login">
          <header className="Login-header">
            <div className="Login-prompt">
              <div className="Login-title">
                <img src={cube} /> ai.vitalacy
              </div>
              <Form onSubmit={_login}>
                <Form.Group>
                <Form.Control
                  id="Input-no-outline"
                  type="user"
                  placeholder="Username"
                  onChange={updateUsername}
                />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id="Input-no-outline"
                    className="Input-no-outline"
                    type="password"
                    placeholder="Password"
                    onChange={updatePassword}
                  />
                </Form.Group>
                <Button type="submit" id="Signin-btn" variant="outline-light" onClick={_login}>
                  Sign-in
                </Button>
                <div className="Signin-error">
                  {errorMsg}
                </div>
              </Form>
              <div className="Signup-link">
                <Link to='/signup' className="Link">Create user account</Link>
              </div>
            </div>
          </header>
          <div className="Login-footer">
            Vitalacy ⋅ 2020
          </div>
        </div>
      )
    }
  }

  return render()
}

export default Login;