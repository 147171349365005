/**
 * Login page
 * @author Jeremy Aftem
 * 
 */

import React, { useState, useEffect } from 'react';
import './Signup.css';
import logo from '../../assets/icons/logo.svg'
import {Link, Redirect} from 'react-router-dom'
import { Form, Button, FormControl, FormGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import cube from '../../assets/layout/cube.png';
import { Auth } from 'aws-amplify';

const Signup = props => {
  const [errorMsg, setErrorMsg] = useState('');
  const [repeatPass, setRepeatPass] = useState('');
  const [passRed, setPassRed] = useState(false);
  const [userCreds, setUserCreds] = useState(null);
  const [userAttr, setUserAttr] = useState({
    username: '',
    password: '',
    attributes: {
      email: '',
      name: ''
    }
  })

  useEffect(()=>{
    emailCheck() 
  }, [userAttr, repeatPass])

  async function _signup(e) {
    // Do not refresh page.
    e.preventDefault();
    if (!emailCheck()) {
      setPassRed(true)
      return
    }
    if (!fieldsFilled()) {
      setErrorMsg("Please fill all fields below.")
      return
    }
    try {
      const { user } = await Auth.signUp(userAttr)
      setUserCreds(user)
    } catch (error) {
      console.log("Error signing up:", error)
      setErrorMsg('Error: ' + error.message)
    }
  }
  function fieldsFilled() {
    if (
      userAttr.email == ''            ||
      userAttr.password == ''         ||
      userAttr.attributes.email == '' ||
      userAttr.attributes.name == '' 
    ) {
      return false
    } else {
      return true
    }
  }
  function updateUsername(e) {
    let username = e.target.value
    let newUserAttr = deep_copy(userAttr)
    newUserAttr.username = username
    setUserAttr(newUserAttr)
  }

  function updatePassword(e) {
    let password = e.target.value
    let newUserAttr = deep_copy(userAttr)
    newUserAttr.password = password
    setUserAttr(newUserAttr)
  }

  function updateRepeatPassword(e) {
    let repeatPassword = e.target.value
    setRepeatPass(repeatPassword)
  }

  function emailCheck() {
    if (repeatPass !== userAttr.password) {
      setErrorMsg('Passwords do not match.')
      return false
    } else if (repeatPass === '' && userAttr.password === '') {
      setErrorMsg('')
      return true
    } else {
      setErrorMsg('')
      return true
    }
  }

  function updateEmail(e) {
    let email = e.target.value
    let newUserAttr = deep_copy(userAttr)
    newUserAttr.attributes.email = email
    setUserAttr(newUserAttr)
    if (validateEmail(email) == false) {
      setErrorMsg('Email is invalid.')
    } else {
      setErrorMsg('')
    }
  }
  function updateName(e) {
    let name = e.target.value
    let newUserAttr = deep_copy(userAttr)
    newUserAttr.attributes.name = name
    setUserAttr(newUserAttr)
  }

  function deep_copy(obj) {
    return JSON.parse(JSON.stringify(obj))
  }

  function validateEmail(email) {
    if (email === '') { return true }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function render() {
    if (userCreds) {
      return (
        <Redirect to={{pathname: '/login'}} push={true} />
      )
    } else {
      return (
        <div className="Signup">
          <header className="Signup-header">
            <div className="Signup-prompt">
              <div className="Signup-title">
                <div>
                  <img src={cube} /> ai.vitalacy.signup
                </div>
                <div className="Signup-desc">
                  After registering, you will need to contact an administrator to activate your account before you can login.
                </div>
              </div>
              <Form>
                <div className="Signup-error">
                  {errorMsg}
                </div>
                <Form.Group>
                  <Form.Control id="Input-no-outline" type="user" placeholder="Username" onChange={updateUsername}/>
                </Form.Group>
                <Form.Group>
                  <Form.Control id="Input-no-outline" placeholder="Email" onChange={updateEmail} />
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    id="Input-no-outline"
                    className="Input-no-outline"
                    type="password"
                    placeholder="Password"
                    onChange={updatePassword}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    id={passRed ? "Input-red-outline" : "Input-no-outline"}
                    className={passRed ? "Input-red-outline" : "Input-no-outline"}
                    type="password"
                    placeholder="Repeat password"
                    onChange={updateRepeatPassword}
                  />
                </Form.Group>
    
                <Form.Group>
                  <Form.Control id="Input-no-outline" placeholder="First and last name" onChange={updateName}/>
                </Form.Group>
                <Button id="Signup-btn" variant="outline-light" type="submit" onClick={_signup}>
                  Sign-up
                </Button>
              </Form>
            </div>
          </header>
          <div className="Signup-footer">
            Vitalacy ⋅ 2020
          </div>
        </div>
      )
    }
  }
  return render()
}

export default Signup;