import React from 'react';
import logo from './assets/icons/logo.svg';
import './App.css';
import Login from './domains/login/Login';
import Signup from './domains/signup/Signup';
import Annotator from './domains/annotator/Annotator';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path='/signup'>
          <Signup />
        </Route>
        <Route path='/annotate'>
          <Annotator />
        </Route>
        <Route path='/'>
          <Login />
        </Route>
      </Switch>
    </Router>
  );
}